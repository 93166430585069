/* color variables */
$white: #fff;
$black:#000;
$black1: rgba(0,0,0,0.12);
$black2: rgba(0,0,0,0.24);
$black3: rgba(64,60,67,.16);
$green: rgb(40, 208, 80);
$green1: rgb(191, 240, 203);
$green2: #28d050;
$green3: #1da53c;
$green-dark: rgb(56, 118, 29);
$grey: #686d77;
$grey1: #ccc;
$grey2: rgb(128, 128, 128);
$grey3: #F6F6F6;
$grey4: #eee;
$grey5: rgba(0, 0, 0, 0.12);
$red1: #c81414;
$red2: #aa1212;