/* legacy font variables */
$fontGlobal:1rem;
$font10:.625rem;
$font12:.75rem;
$font14:.875rem;
$font16:1rem;
$font18:1.125rem;
$font21:1.313rem;
$font24:1.5rem;
$font25:1.6rem;
$font30:1.875rem;
$font32:2rem;
$font33:2.063rem;
$font30b: 2.4rem; // 30px for h2 titles
$font40:2.5rem;
$font62:3.875rem;

/*custom fonts*/
@font-face {
    font-family: "Mark OT";
    src: url('../../assets/fonts/MarkOTReg.eot');
}
/**/