/* You can add global styles to this file, and also import other style files */
@import './app/styles/colors';
@import './app/styles/fonts';

@mixin set-custom-focus {
    border: solid 1px $green2;
    box-shadow: none;
}

@mixin set-full-width-and-font {
    width: 100%;
    font-size: $font14;
    font-family: 'Mark OT', sans-serif;
}

body {
    font-family: 'Mark OT', sans-serif;
    font-size: 16px;
    min-height: 100vh; 
    margin: 0; 
    padding: 0; 
    color: $grey;
    font-weight: normal;
}

h1, h2, h3, h4, h5 {
    color: $black;
}

.content {
    min-height: calc(100vh - 70px);
    padding: 10px 25px 10px 25px;
}

.fields {
    display: flex;
    flex-direction: column;
    padding: 6px 2px;
    color: $grey;
    width: 100%;

    label {
        font-weight: bold;
        font-size: $font14;
        padding: 4px;
    }

    span {
        font-size: $font14;
        padding-left: 5px;
    }

    small {
        color: $red1;
        font-size: $font12;
        padding: 4px;
    }
}

.same-line-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 998px) {
        flex-direction: column;
    }
}

.pull-up {
    position: relative;
    bottom: 6px;
}

.pull-little-up {
    position: relative;
    bottom: 2px;
}

.pull-down {
    position: relative;
    top: 10px;
}

.tooltip {
    font-size: $font12;
}

// primeFlexCSS styles to be applied to all components
.grid {
    margin: 0;
}

.p-component {
    font-family:'Mark OT', sans-serif;
}

.p-dialog {
    .p-dialog-header {
        padding: 10px 14px 10px 28px;
        border-bottom: solid 1px $grey1;

        .p-dialog-header-icon:focus, .p-dialog-header-icon:enabled:hover {
            box-shadow: 0 0 0 0.2rem $green1;
            background-color: $green1;
        }

        .p-dialog-header-icon:focus {
            box-shadow: 0 0 0 0.2rem $green1;
        }

        .p-dialog-title {
            font-size: $fontGlobal;
            font-weight: 600;
        }
    }

    .p-dialog-content {
        padding: 1rem 1.5rem 2rem 1.5rem;
    }
}

.p-dialog-content {
    overflow: visible !important;
}

// primeNG common styles to be applied to all components
.p-disabled, .p-component:disabled {
    background-color: $grey4;
    cursor: not-allowed;
}

.p-ripple.p-element.p-button-sm {
   
    .p-button-label {
        font-size: $font14;
        white-space: nowrap;
    }
}

.p-button {
    background-color: $green2;
    border: solid 2px $green2;

    .p-button-raised {
        box-shadow: none;
    }
}

.p-button:focus, .p-button:enabled:hover, .p-ripple.p-element.p-button-sm:hover {
    box-shadow: 2px 5px 9px $black1, 2px 5px 9px $black2;
}

.p-button:enabled:hover {
    background-color: $green3;
    border: solid 2px $green3;
}

.p-ripple.p-element.p-button-sm.p-button-danger {
    background-color: $red1;
    border: solid 2px $red1;
}

.p-ripple.p-element.p-button-sm.p-button-danger:hover {
    background-color: $red2;
    border: solid 2px $red2;
}

.p-ripple.p-element.p-button-sm.p-button-success {
    background-color: $green2;
    border: solid 2px $green2;
}

.p-ripple.p-element.p-button-sm.p-button-success:hover {
    background-color: $green3;
    border: solid 2px $green3;
}

.p-ripple.p-element.p-button-sm.p-button-outlined {
    background-color: $white;
    border: solid 2px $green2;
    color: $green2;
}

.p-ripple.p-element.p-button-sm.p-button-outlined:hover {
    background-color: $white;
    border: solid 2px $green3;
    color: $green-dark;
}

.p-card {
    border: solid 2px $green2;
    box-shadow: none;
    font-family: 'Mark OT', sans-serif;
    background-color: $grey3;
    min-height: 637px;

    .p-card-title {
        font-size: 18px;
        font-weight: normal;
    }

    .p-card-content {
        padding: 0;
    }
}

.p-inputmask {
   @include set-full-width-and-font;
}

.p-inputnumber {
    @media screen and (max-width: 998px) {
        @include set-full-width-and-font;
    }
}

.p-inputtext {
    @include set-full-width-and-font;
    height: 35px;
}

.p-inputtext:enabled:hover, .p-inputtext:enabled:focus {
    @include set-custom-focus;
    box-shadow: 0 1px 1px 1px $green1;
}

.p-inputtextarea {
    height: 120px;
}

.p-calendar-w-btn .p-datepicker-trigger {
    height: 35px;
}

.p-datepicker {
    table {
        td.p-datepicker-today > span.p-highlight, td > span.p-highlight {
            background-color: $green1;
            color: $green-dark;
        }
    }

    .p-datepicker-header .p-datepicker-prev:focus,
    .p-datepicker-header .p-datepicker-next:focus,
    .p-link:focus {
         box-shadow: 0 0 0 0.2rem $green1;
    }

    .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
    .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
        color: $green-dark;
    }
}

.p-datepicker table td > span:focus {
    box-shadow: 0 0 0 0.2rem $green1;
}

.p-dropdown {
    @include set-full-width-and-font;
    height: 34px;

    .p-dropdown-panel {
        border: solid 1px $grey1;
        border-radius: 0 0 5px 5px;

        .p-dropdown-items .p-dropdown-item {
            padding: 2px 8px;
            font-size: $font14;
        }
    }
}

.p-dropdown:not(.p-disabled).p-focus, .p-dropdown:not(.p-disabled):hover {
    @include set-custom-focus;
    box-shadow: 0 1px 1px 1px $green1;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: $green-dark;
    background-color: $green1;
}

.p-radiobutton {
    .p-radiobutton-box.p-highlight,
    .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        background: $green2;
        border-color: $green2;
    }

    .p-radiobutton-box:not(.p-disabled).p-focus,
    .p-radiobutton-box:not(.p-highlight):not(.p-disabled):hover {
        box-shadow: none;
        border-color: $green2;
    }
} 

.p-scrollpanel {
    height: 537px;
    background-color: $white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 1px $black3;
}

.p-progress-spinner .p-progress-spinner-circle {
    stroke: $green2;
    animation: none;
}

.p-tabview {
    border: solid 2px $green2;
    font-family: 'Mark OT', sans-serif;
    background-color: $grey3;
    border-radius: 8px;

    .p-tabview-nav {
        border: none;
        border-radius: 9px 9px 0px 0px;

        li {
            .p-tabview-nav-link:not(.p-highlight):not(.p-disabled):hover, 
            .p-tabview-nav-link:not(.p-disabled):focus {
                color: $green2;
                box-shadow: inset 0 0 0 0.2rem $green1;
            }
        }
  
        li.p-highlight {
            .p-tabview-nav-link {
                background-color: $green1;
                border: solid 1px $green2;
                border-bottom: none;
                border-radius: 6px 6px 0 0;
                color: $green-dark;
            }

            .p-tabview-nav-link:focus {
                box-shadow: inset 0 0 0 0.2rem $green1;
            }
        } 
    }

    .p-tabview-panels {
        background-color: $grey3;
        padding: 20px;
    }
}